.ComparisonModal {
    .ant-modal .ant-modal-content {
        padding: 0 0 0;
        background-color: #edeff5;
    }
    .ant-table table {
        border-spacing: 0 10px;
    }
    .ant-table-thead .ant-table-cell {
        background-color: white;
        border: none
    }
    .ant-table-footer {
        background-color: white;
        border: none
    }
    .ant-table-tbody > tr > td {
        border: none
    }
}
.table-matching-row {
    margin-top: 1px;
    margin-bottom: 1px;
    background-color: #EDF9F0;
}
.table-row-not-matching {
    background-color: #FFF4EC;
}
.bold-table-column {
    font-weight: bold;
}