.str-chat-channel-list {
  height: fit-content;
  width: 100%;
}

.str-chat__load-more-button {
  display: none;
}

.team-channel-list {
  width: 100%;

  .str-chat__avatar-fallback {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-color)
  }

  .team-channel-list__message {
    padding: 0 16px;
  }

  .team-channel-list__message.loading {
    max-height: 115px;
  }

  .team-channel-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .team-channel-list__header__title {
      height: 16px;
      margin-bottom: 10px;
    }

    button {
      display: flex;
      align-items: center;
      padding-block: 4px;
    }
  }
}
