.channel-empty__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 20px;


  .channel-empty__avatars {
    display: flex;
  }

  .channel-empty__avatars div:first-child {
    z-index: 3;
  }

  .channel-empty__avatars div:nth-child(2) {
    // position: relative;
    right: 32px;
    z-index: 2;
  }

  .channel-empty__avatars div:nth-child(3) {
    // position: relative;
    right: 64px;
    z-index: 1;
  }

  .channel-empty__avatars .str-chat__avatar {
    margin-right: 4px;
  }

  .channel-empty__main-description {
    margin-bottom: 10px;
  }

  .channel-empty__secondary-description {
    margin: 0;
  }
}